<template>
	<div class="tabbar">
		<div class="tabbar-list">
			<div class="tabbar-item" v-for="(item,index) in list" :key="index" @click="goRouter(item)">
				<img class="img" :src="index == barCurrent ? item.selectedIconPath : item.iconPath" />
				<div class="title" :class="index == barCurrent ? 'new-text' : 'text'">{{item.text}}</div>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		name: "tabbar",
		props: {
			current: {
				type: Number,
				default: 0
			},
		},
		data() {
			return {
				barCurrent: 0,
				list: [
                    {
						pagePath: "/",
						iconPath: new URL('@/assets/icon/1.png', import.meta.url).href,
						selectedIconPath: new URL('@/assets/icon/11.png', import.meta.url).href,
						text: this.$t('产品趋势')
					}, 
					{
						pagePath: "/record",
						iconPath: new URL('@/assets/icon/2.png', import.meta.url).href,
						selectedIconPath: new URL('@/assets/icon/22.png', import.meta.url).href,
						text: this.$t('订单历史')
					},
					{
						pagePath: "/service",
						iconPath: new URL('@/assets/icon/3.png', import.meta.url).href,
						selectedIconPath: new URL('@/assets/icon/33.png', import.meta.url).href,
						text: this.$t('客服')
					},
					{
						pagePath: "/mine",
						iconPath: new URL('@/assets/icon/4.png', import.meta.url).href,
						selectedIconPath: new URL('@/assets/icon/44.png', import.meta.url).href,
						text: this.$t('我的账户')
					}
				]
			}
		},
		watch: {
			current: {
				immediate: true,
				handler(val) {
					this.barCurrent = val
				},
				deep: true
			},
		},
		methods: {
            // 切换
			goRouter(item) {
                this.$router.push(item.pagePath)
			}
		}
	}
</script>

<style lang="scss" scoped>
	.tabbar {
		width: 100%;
		position: fixed;
        left: 0;
		bottom: 0;
		border-top: 1px solid #444444;
		background-color: #333;
		font-size: 12px;
		z-index: 999;

		.tabbar-list {
			display: flex;
            justify-content: space-between;
			align-items: center;
			height: 50px;
			padding: 10px 0;

			.tabbar-item {
				flex: 1;
				display: flex;
				flex-direction: column;
                align-items: center;
				text-align: center;

				.img {
					width: 22px;
					height: 22px;
					padding-bottom: 5px;

					img {
						width: 100%;
						height: 100%;
					}
				}

				.text {
					color: #bbbbbb;
				}

				.new-text {
					color: #fddeb6;
				}
			}
		}
	}
</style>