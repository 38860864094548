import pako from 'pako';

class WebSocketService {
  constructor(url, onMessageCallback) {
    this.url = url;
    this.onMessageCallback = onMessageCallback;
    this.websocket = null;
    this.reconnectInterval = 5000; // 重连间隔时间
    this.heartbeatInterval = 10000; // 心跳间隔时间
    this.heartbeatTimer = null;
    this.reconnectTimer = null;
    this.connect();
  }

  connect() {
    this.websocket = new WebSocket(this.url);

    this.websocket.onopen = (event) => {
      // console.log('WebSocket connection opened:', event);
      this.startHeartbeat();
    };

    this.websocket.onmessage = (event) => {
      this.resetHeartbeat();
      if (event.data instanceof Blob) {
        this.handleBlobMessage(event.data);
      } else {
        this.onMessageCallback(event.data);
      }
    };

    this.websocket.onclose = () => {
      // console.log('WebSocket connection closed');
      this.stopHeartbeat();
      this.reconnect();
    };

    this.websocket.onerror = (event) => {
      // console.error('WebSocket error:', event);
      this.stopHeartbeat();
      this.reconnect();
    };
  }

  handleBlobMessage(blob) {
    const reader = new FileReader();
    reader.onload = () => {
      const arrayBuffer = reader.result;
      const data = new Uint8Array(arrayBuffer);
      const inflatedData = pako.inflate(data);
      const text = new TextDecoder().decode(inflatedData);

      try {
        const jsonData = JSON.parse(text);
        if (jsonData.ping) {
          this.startHeartbeat(jsonData.ping);
          if (this.onMessageCallback) {
            this.onMessageCallback(jsonData);
          }
        } else if (jsonData.id && jsonData.status === 'ok') {
          console.log('Subscription success:', jsonData);
        } else {
          if (this.onMessageCallback) {
            this.onMessageCallback(jsonData);
          }
        }
      } catch (error) {
        // console.error('Error parsing JSON data:', error);
      }
    };
    reader.readAsArrayBuffer(blob);
  }

  startHeartbeat(pingValue) {
    if (pingValue) {
      const data = JSON.stringify({ pong: pingValue });
      this.heartbeatTimer = setInterval(() => {
        if (this.websocket && this.websocket.readyState === WebSocket.OPEN) {
          this.websocket.send(data);
        }
      }, this.heartbeatInterval);
    }
  }

  resetHeartbeat() {
    clearInterval(this.heartbeatTimer);
    this.startHeartbeat();
  }

  stopHeartbeat() {
    clearInterval(this.heartbeatTimer);
    this.heartbeatTimer = null;
  }

  reconnect() {
    if (this.reconnectTimer) {
      return;
    }

    this.reconnectTimer = setTimeout(() => {
      console.log('Reconnecting to WebSocket...');
      this.connect();
      this.reconnectTimer = null;
    }, this.reconnectInterval);
  }

  sendMessage(message) {
    if (this.websocket && this.websocket.readyState === WebSocket.OPEN) {
      this.websocket.send(message);
    } else {
      console.error('WebSocket is not open. Unable to send message.');
    }
  }

  disconnect() {
    if (this.websocket) {
      this.websocket.onclose = null; // Prevent triggering reconnect
      this.websocket.close();
      this.stopHeartbeat();
      this.websocket = null;
    }
  }
}

export default WebSocketService;
